<template>
    <a-config-provider :locale="locale">
        <div id="app">
            <router-view></router-view>
        </div>
    </a-config-provider>
</template>
<script>
import zhCN from 'ant-design-vue/lib/locale-provider/zh_CN';
export default {
    data() {
        return {
            locale: zhCN
        }

    },
    name: 'App',
    created() {
    }

}
</script>
<style>
#app {
    /*font-family: Avenir, Helvetica, Arial, sans-serif;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        text-align: center;
        color: #2c3e50;*/
    height: 100%;
}

/* table文本溢出省略 */
.ant-table td {
    max-width: 200px;
    min-width: 70px;
}

.amap-logo {
    display: none!important;
} 

.amap-copyright {
    bottom:-100px;
    display: none!important;
}
.ant-table-row-cell-break-word{
    height:auto !important;
}
</style>