import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

const state = {
    siteInfo: {
        systemName: "",
        belongDepart: "",
        copyright: "",
        copyrightNumber: "",
        developer: "",
        website: ""
    },
	
}

const mutations = {
    setSiteInfo(state, n) {
        return (state.siteInfo = n);
    }
}
export default new Vuex.Store({
    state, mutations
})