import router from './router.js'
var user = {
    get userInfo() {
        if (JSON.parse(localStorage.getItem("userInfo")) == null) {
            return {};
        }
        else {
            return JSON.parse(localStorage.getItem("userInfo"))
        }
    },
    set userInfo(info) { localStorage.setItem("userInfo", JSON.stringify(info)); },

    get token() {
        if (localStorage.getItem("token") == null) {
            return {};
        }
        else {
            let a = {};
            try {
                a=JSON.parse(localStorage.getItem("token"));
            }
            catch (ex) {
                localStorage.getItem("token") == null;
            }
            return a;
        }
    },
    set token(tk) { localStorage.setItem("token", JSON.stringify(tk)); },

    get asyncRoutes() {
        if (localStorage.getItem("asyncRoutes") == null) {
            return [];
        }
        else {
            return JSON.parse(localStorage.getItem("asyncRoutes"));
        }
    },
    set asyncRoutes(json) {
        if (json == null) {
            json = [];
        }
        localStorage.setItem("asyncRoutes", JSON.stringify(json));
    },

    loginOut: function () {
        localStorage.removeItem("userInfo");
        localStorage.removeItem("token");
        localStorage.removeItem("asyncRoutes");
        router.push({ path: "/" });
    }
};

export default {
    user
}