/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'maintain': {
    width: 30,
    height: 30,
    viewBox: '0 0 30 30',
    data: '<path pid="0" d="M29.62 12s.375 1.6.375 2.801c0 1.4-.375 3.198-.375 3.198-.186 1.002-.75 1.601-1.499 1.601h-.188c-2.068.008-3.742 1.794-3.748 4 0 .8.373 1.599.373 1.599.377.8.189 1.802-.56 2.4l-3.75 2.2c-.189.201-.563.201-.75.201-.563 0-1.126-.2-1.499-.6-.376-.4-1.875-1.8-3.001-1.8-1.125 0-2.437 1.2-2.999 1.8-.376.4-.937.6-1.5.6-.375 0-.563-.2-1.125-.2l-3.563-2.2c-.562-.6-.937-1.6-.562-2.4 0 0 .376-.6.376-1.4-.006-2.206-1.681-3.993-3.75-3.999-.75 0-1.312-.6-1.499-1.6-.001-.2-.376-1.803-.376-3.203C0 13.6.375 12 .375 12c.188-1 .75-1.8 1.312-1.6h.188c2.068-.007 3.742-1.793 3.748-3.999 0-.8-.374-1.599-.374-1.599-.375-.8 0-1.802.75-2.4L9.938.201c.183-.2.37-.2.746-.2.562 0 1.125.2 1.311.6.565.6 1.879 1.798 3.002 1.798 1.126 0 2.625-1.2 3-1.8.374-.4.937-.6 1.499-.6.188 0 .562.2.937.4l3.75 2.2c.563.6.937 1.599.563 2.398 0 0-.375.802-.375 1.402.007 2.206 1.682 3.992 3.75 3.998.749 0 1.312.6 1.498 1.601v.002zm-1.687 3c0-.999-.187-1.999-.187-2.6-1.499 0-2.811-.598-3.749-1.8-.938-1.199-1.499-2.599-1.499-4.198 0-1 .187-1.8.373-2.2l-3.373-2c-.408.435-.847.836-1.312 1.199-1.126.8-2.25 1.2-3.188 1.2-.938 0-1.874-.399-2.999-1.2-.46-.37-.899-.77-1.312-1.2L7.312 4.204c0 .4.375 1.2.375 2.2 0 1.599-.563 2.999-1.687 4.198-.938 1-2.249 1.6-3.75 1.8 0 .4-.188 1.6-.188 2.6s.188 2.201.188 2.6c1.5 0 2.812.8 3.936 1.8.938 1.2 1.5 2.6 1.5 4.2 0 1-.187 1.8-.374 2.2l3.187 2c.188-.2.563-.6 1.312-1.2 1.124-1 2.249-1.4 3.187-1.4.937 0 2.062.6 3.188 1.4.75.6 1.124 1 1.312 1.2l3.373-2c0-.4-.375-1.2-.375-2.2 0-1.6.563-3 1.5-4.2.939-1 2.25-1.598 3.75-1.8 0-.399.187-1.598.187-2.6V15zm-12.56-5.999c3 0 5.44 2.6 5.44 5.999 0 3.4-2.44 6-5.44 6-2.997 0-5.436-2.6-5.436-6s2.439-5.999 5.439-5.999h-.002zm0 9.599c1.876 0 3.376-1.6 3.376-3.6 0-1.999-1.5-3.598-3.375-3.598-1.874 0-3.375 1.599-3.375 3.598 0 2 1.5 3.6 3.375 3.6z" _fill="#FFF" fill-rule="nonzero"/>'
  }
})
