import AMapLoader from '@amap/amap-jsapi-loader'

const plugins = [
    'Geocoder',
    'DistrictSearch',
    'Polygon',
    'Marker',
    'InfoWindow',
    'Pixel',
    'ToolBar'
]
export function getAMap () {
    return AMapLoader.load({
        key: '0529f8f19e44cb2868f995c7e8ac623f',
        version: '1.4.15',
        plugins: plugins.map(plugin => `AMap.${plugin}`)
    })
    .then(AMap => AMap)
}
