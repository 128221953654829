import Vue from 'vue'
import Antd from 'ant-design-vue';
import 'ant-design-vue/dist/antd.css';
import SvgIcon from 'vue-svgicon'
import '@/components/icons'
import App from './App.vue'
import router from './router.js'
import store from './vuex/store' // ����store
import "../public/public.css"
import * as jsc from '../public/js/Jscript.js'
import html2canvas from "../public/js/html2canvas.min.js"

import md5 from 'js-md5'
Vue.prototype.$md5 = md5

Vue.use(Antd)
Vue.config.productionTip = false;
import User from './user.js';
Vue.prototype.$User = User;

import axios from 'axios';
import { Date } from 'core-js';
//axios.defaults.baseURL = "http://192.168.0.220:8005";
//axios.defaults.fileURL = "http://192.168.0.220:8002";
//axios.defaults.baseURL = "http://58.210.32.10:8002"
//axios.defaults.fileURL = "http://58.210.32.10:8004"
axios.defaults.baseURL = "http://api.kssfb.com"
axios.defaults.fileURL = "http://file.kssfb.com"
//����
//axios.defaults.baseURL = "http://192.168.0.240:8006";
//axios.defaults.fileURL = "http://192.168.0.220:8002";
//axios.defaults.withCredentials = true;
axios.defaults.headers.common["Authorization"] = User.user.token.access_token;
Vue.prototype.$axios = axios;
Vue.prototype.$jsc = jsc;
Vue.prototype.$html2canvas = html2canvas;

Vue.use(SvgIcon, {
    tagName: 'svg-icon',
    defaultWidth: '1em',
    defaultHeight: '1em'
})

import { getAMap } from './assets/js/tools'

!(async function () {
    Vue.prototype.$map = await getAMap()
}())

//·������
router.beforeEach((to, from, next) => {
    from;
    if (to.path == "/" || to.path == "/login" || to.path == "/appdown" || to.path === "/shlzpxt" || to.path === "/hdspzpxt" || to.path === "/hdshslgczhglxt" || to.path === "/sgcxczgxxxt") {
        User.user.userInfo.RefreshTime = new Date();//����ˢ��ʱ��
        next();
    } else {
        if (User.user.token.access_token == undefined) {
            next({ path: "/login" })
        } else {
            //���Ǵӵ�½ҳ���Ľ���δ����Ч��
            if (from.path != "/login") {
                var fdsj = (new Date().getTime() - new Date(User.user.userInfo.RefreshTime)) / 1000 / 60;//�ҳ�������û��ˢ����
                if (fdsj > 30) {//30����û�������˳�
                    console.log(fdsj);
                    User.user.loginOut();
                    return false;
                }
            }

            //����token����Ч��
            var expires_time = new Date(User.user.token.expires_time);
            var currentTime = new Date();
            if (expires_time <= currentTime) {//��ʾtoken������
                User.user.loginOut();
                return;
            }
            User.user.userInfo.RefreshTime = new Date();//����ˢ��ʱ��
            next();
        }
    }
});

//ȡϵͳ��Ϣ��store
axios({
    url: '/api/comon/GetSystemInfo'
}).then(res => {
    store.commit('setSiteInfo', res.data.data);
    document.title = res.data.data.systemName;
}).catch(error => {
});

import _ from 'lodash'
Vue.prototype._ = _

new Vue({
    router,
    store,
    components: { App },
    render: h => h(App),
}).$mount('#app')