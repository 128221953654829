/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'exit': {
    width: 30,
    height: 30,
    viewBox: '0 0 30 30',
    data: '<path pid="0" d="M25.73 16.25H8.608a1.25 1.25 0 110-2.5H25.73l-2.986-2.982A1.25 1.25 0 1124.514 9l5.12 5.117a1.25 1.25 0 010 1.768l-5.12 5.12a1.252 1.252 0 01-1.77-1.767l2.986-2.988zM16.296 27.5v-2.093a1.25 1.25 0 012.501 0v3.343c0 .69-.56 1.25-1.25 1.25H1.25C.56 30 0 29.44 0 28.75V1.25C0 .56.56 0 1.25 0h16.324c.69 0 1.25.56 1.25 1.25v3.343a1.25 1.25 0 01-2.5 0V2.5H2.5v25h13.795z" _fill="#FFF" fill-rule="nonzero"/>'
  }
})
