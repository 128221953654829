/* 加密 */
const DesEncrypt = function (that, value) {
    return that.$axios({
        url: `/api/comon/DesEncrypt?str=${value}`,
        method: 'get'
    }).then((res) => { return res }).catch(() => {
        return ''
    })
}

/* 解密 */
const DesDecrypt = function (that, value) {
    return that.$axios({
        url: `/api/comon/DesDecrypt?str=${value}`,
        method: 'get'
    }).then((res) => { return res }).catch(() => {
        return ''
    })
}
const formatDate = function (value) {
    let date = new Date(value);
    if (value == "0001-01-01 00:00:00" || value == null || value == "1900-01-01 00:00:00" || value == "") {
        return "";
    } else {
        let m = date.getMonth()+1 < 10 ? "0" + parseInt(date.getMonth() + 1) : parseInt(date.getMonth() + 1);
        let d = date.getDate() < 10 ? "0" + date.getDate() : date.getDate();
        return date.getFullYear() + "-" + m + "-" + d;
    }
}
const formatDate_first7 = function (value) {
    let date = new Date(value);
    date.setDate(date.getDate() + 7);
    if (value == "0001-01-01 00:00:00" || value == null || value == "1900-01-01 00:00:00" || value == "") {
        return "";
    } else {
        let m = date.getMonth()+1 < 10 ? "0" + parseInt(date.getMonth() + 1) : parseInt(date.getMonth() + 1);
        let d = date.getDate() < 10 ? "0" + date.getDate() : date.getDate();
        return date.getFullYear() + "-" + m + "-" + d;
    }
}
const formatDate_custom = function (value,day=0) {
    let date = new Date(value);
    date.setDate(date.getDate() + day);
    if (value == "0001-01-01 00:00:00" || value == null || value == "1900-01-01 00:00:00" || value == "") {
        return "";
    } else {
        let m = date.getMonth()+1 < 10 ? "0" + parseInt(date.getMonth() + 1) : parseInt(date.getMonth() + 1);
        let d = date.getDate() < 10 ? "0" + date.getDate() : date.getDate();
        return date.getFullYear() + "-" + m + "-" + d;
    }
}
const formatDate_hz = function (value) {
    let date = new Date(value);
    if (value == "0001-01-01 00:00:00" || value == null || value == "1900-01-01 00:00:00" || value == "") {
        return "";
    } else {
        return date.getFullYear() + "年" + parseInt(date.getMonth() + 1) + "月" + date.getDate() + "日";
    }
}
//取  月-日 时：分
const formatDate_yMhm = function (value) {
    let date = new Date(value);
    if (value == "0001-01-01 00:00:00" || value == null || value == "1900-01-01 00:00:00" || value == "") {
        return "";
    } else {
        let m = date.getMonth()+1 < 10 ? "0" + parseInt(date.getMonth() + 1) : parseInt(date.getMonth() + 1);
        let d = date.getDate() < 10 ? "0" + date.getDate() : date.getDate();
        let h = date.getHours() < 10 ? "0" + date.getHours() : date.getHours();
        let mi = date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes();
        return m + "-" + d + " " + h + ":" + mi;
    }
}
const formatDate_isTime = function (value) {
    let date = new Date(value);
    let dateNow = new Date();
    if (value == "0001-01-01 00:00:00" || value == null || value == "1900-01-01 00:00:00" || value == "") {
        return "";
    } else {
        let a_year = date.getFullYear() + "-";
        let a_month = (date.getMonth() + 1 < 10 ? "0" + parseInt(date.getMonth() + 1) : parseInt(date.getMonth() + 1)) + "-";
        let a_day = (date.getDate() < 10 ? "0" + date.getDate() : date.getDate());

        let a_Nowyear = dateNow.getFullYear() + "-";
        let a_Nowmonth = (dateNow.getMonth() + 1 < 10 ? "0" + parseInt(dateNow.getMonth() + 1) : parseInt(dateNow.getMonth() + 1)) + "-";
        let a_Nowday = dateNow.getDate() < 10 ? "0" + dateNow.getDate() : dateNow.getDate();

        let a_time = a_year + a_month + a_day;
        let a_Nowtime = a_Nowyear + a_Nowmonth + a_Nowday;

        a_time = a_time.replace(a_Nowtime, "");
        a_time = a_time.replace(a_Nowyear + a_Nowmonth, "");
        a_time = a_time.replace(a_Nowyear, "");

        let hour = date.getHours() < 10 ? "0" + date.getHours() : date.getHours();
        let minite = date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes();
        let second = date.getSeconds() < 10 ? "0" + date.getSeconds() : date.getSeconds();
        return a_time + " " + hour + ":" + minite + ":" + second;
    }
}
const formatDate_isTime_new = function (value) {
    let date = new Date(value);
    let dateNow = new Date();
    if (value == "0001-01-01 00:00:00" || value == null || value == "1900-01-01 00:00:00" || value == "") {
        return "";
    } else {
        let a_year = date.getFullYear() + "-";
        let a_month = (date.getMonth() + 1 < 10 ? "0" + parseInt(date.getMonth() + 1) : parseInt(date.getMonth() + 1)) + "-";
        let a_day = (date.getDate() < 10 ? "0" + date.getDate() : date.getDate());

        let a_Nowyear = dateNow.getFullYear() + "-";
        let a_Nowmonth = (dateNow.getMonth() + 1 < 10 ? "0" + parseInt(dateNow.getMonth() + 1) : parseInt(dateNow.getMonth() + 1)) + "-";
        let a_Nowday = dateNow.getDate() < 10 ? "0" + dateNow.getDate() : dateNow.getDate();

        let a_time = a_year + a_month + a_day;
        let a_Nowtime = a_Nowyear + a_Nowmonth + a_Nowday;

        // a_time = a_time.replace(a_Nowtime, "");
        // a_time = a_time.replace(a_Nowyear + a_Nowmonth, "");
        // a_time = a_time.replace(a_Nowyear, "");

        let hour = date.getHours() < 10 ? "0" + date.getHours() : date.getHours();
        let minite = date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes();
        let second = date.getSeconds() < 10 ? "0" + date.getSeconds() : date.getSeconds();
        return a_time + " " + hour + ":" + minite + ":" + second;
    }
}
const delHtmlTag = function (value) {
    return value.replace(/<[^>]+>/g, "");
}
const m_substring = function (value) {
    if (value.length>15) {
        return value.substring(0, 15) + "....";
    } else {
        return value;
    }
}


const picCompress = function (
    file,//文件
    maxsize,//压缩后的最大尺寸，像素为单位
    uploadfun//缩完后的回调，会带一个压好的formData
) {
    let form = new FormData();
    let photoCompress = function (file, w, objDiv) {
        var ready = new FileReader();
        ready.readAsDataURL(file);
        ready.onload = function () {
            var re = this.result;
            canvasDataURL(re, w, objDiv)
        }
    };

    let canvasDataURL = function (path, obj, callback) {
        var img = new Image();
        img.src = path;
        img.onload = function () {
            var that = this;
            var w = that.width,
                h = that.height,
                scale = w / h,
                quality = 1;
            if (obj.maxsize != undefined) {
                if (w > h) {
                    if (w > obj.maxsize) {
                        h = obj.maxsize / w * h;
                        w = obj.maxsize;

                    }
                } else {
                    if (h > obj.maxsize) {
                        w = obj.maxsize / h * w;
                        h = obj.maxsize;

                    }
                }
            }
            else {
                w = obj.width || w;
                h = obj.height || (w / scale);
            }
            if (obj.quality && obj.quality <= 1 && obj.quality > 0) {
                quality = obj.quality;
            }
            var canvas = document.createElement('canvas');
            var ctx = canvas.getContext('2d');
            var anw = document.createAttribute("width");
            anw.nodeValue = w;
            var anh = document.createAttribute("height");
            anh.nodeValue = h;
            canvas.setAttributeNode(anw);
            canvas.setAttributeNode(anh);
            ctx.drawImage(that, 0, 0, w, h);
            var base64 = canvas.toDataURL('image/jpeg', quality);
            callback(base64);
        }
    }
    let convertBase64UrlToBlob = function (urlData) {
        var arr = urlData.split(','), mime = arr[0].match(/:(.*?);/)[1],
            bstr = atob(arr[1]), n = bstr.length, u8arr = new Uint8Array(n);
        while (n--) {
            u8arr[n] = bstr.charCodeAt(n);
        }
        return new Blob([u8arr], { type: mime });
    }

    photoCompress(file, { maxsize: maxsize }, function (base64Codes) {
        var bl = convertBase64UrlToBlob(base64Codes);

        form.append("file", bl, file.name);
        uploadfun(form);
    });
}

export {
    DesEncrypt,
    DesDecrypt,
    formatDate,
    formatDate_hz,
    formatDate_first7,
    formatDate_custom,
    formatDate_yMhm,
    formatDate_isTime,
    m_substring,
	delHtmlTag,
	picCompress,
	formatDate_isTime_new
}