//引入vue
import Vue from 'vue';
//引入vue-router
import VueRouter from 'vue-router';
Vue.use(VueRouter)

import User from './user.js';

//下面代码可以不产生点击重复路由的报错
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
    return originalPush.call(this, location).catch(err => err)
}
//定义静态路由的集合，数组类型
var routes = [
    {
        "path": "/",
        "component": () => import('@/App'),
        "redirect": "/login",
    },
    {
        "path": "/login",
        "component": () => import('@/login'),
    },
    {
        "path": "/appdown",
        "component": () => import('@/appdown'),
    },
    {
        "path": "/desk",
        "name": "/desk",
        "parentId": -1,
        "redirect": "",
        "component": () => import('@/desk')
    },
    {
        "path": "/hdshslgczhglxt",
        "name": "Hdshslgczhglxt",
        "component": () => import('@/views/hdshslgczhglxt.vue'),
        "meta": {
            "desc": "环淀山湖水利工程综合管理系统"
        }
    },
    {
        "path": "/shlzpxt",
        "name": "Shlzpxt",
        "component": () => import('@/views/shlzpxt.vue'),
        "meta": {
            "desc": "水葫芦抓拍系统"
        }
    },
    {
        "path": "/hdspzpxt",
        "name": "Hdspzpxt",
        "component": () => import('@/views/hdspzpxt.vue'),
        "meta": {
            "desc": "河道视频抓拍系统"
        }
    },
    {
        "path": "/sgcxczgxxxt",
        "name": "Sgcxczgxxxt",
        "component": () => import('@/views/sgcxczgxxxt.vue'),
        "meta": {
            "desc": "水工程巡查整改信息系统"
        }
    }
];
//获取用户路由并加到静态路由表中
const asyncRoutes = getAsyncRoutes(User.user.asyncRoutes);
routes = routes.concat(asyncRoutes);
//console.log(routes);
//实例化VueRouter并将routes添加进去
const router = new VueRouter({//ES6简写，等于routes：routes
    routes
});
//将json格式的路由转为数组（主要是把component换成真实的组件）
export function getAsyncRoutes(routes) {
    const res = []
    // 定义路由中需要的自定名
    const keys = ['path', 'name', 'children', 'redirect', 'meta', 'parentId', 'title', 'tag', 'iconLink']
    // 遍历路由数组去重组可用的路由
    routes.forEach(item => {
        const newItem = {};
        if (item.component) {
            newItem.component = resolve => require([`@/${item.component}`], resolve)
        }
        for (const key in item) {
            if (keys.includes(key)) {
				if(key=='meta')
				{
					if(item[key].buttons==null)
					{
						item[key].buttons=[];
					}
				}
                newItem[key] = item[key]
            }

        }
        // 若遍历的当前路由存在子路由，需要对子路由进行递归遍历
        if (newItem.children && newItem.children.length) {
            newItem.children = getAsyncRoutes(item.children)
        }
		
		
        if (JSON.stringify(newItem) != "{}") {
            res.push(newItem);
        }
    })
    // 返回处理好且可用的路由数组
    return res
}

//根据path找路由
export function pathFindRouter(path) {
    let r = User.user.asyncRoutes;
    let reval;
    function aa(c1) {
        for (let i = 0; i < c1.length; i++) {
            if (c1[i].path == path) {
                reval = c1[i];
                break;
            }
            else {
                if (c1[i].children) {
                    aa(c1[i].children);
                }
            }
        }
        return reval;
    }
    return aa(r);
}

//根据id找出字路由元素
export function findIdSon(id) {
    let r = User.user.asyncRoutes;
    let reval=[];
    function aa(c1) {
        for (let i = 0; i < c1.length; i++) {
            if (c1[i].parentId == id) {
                reval.push(c1[i]);
            }
            else {
                if (c1[i].children) {
                    aa(c1[i].children);
                }
            }
        }
        return reval;
    }
    return aa(r);
}

//抛出这个这个实例对象方便外部读取以及访问
export default router