/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'video': {
    width: 30,
    height: 30,
    viewBox: '0 0 30 30',
    data: '<path pid="0" d="M7.669 22.817l-3.203 6.646c-.253.397-.842.596-1.18.298-.337-.298-.505-.893-.337-1.389l2.781-5.555H1.685C.758 22.817 0 21.924 0 20.833V1.984C0 .893.758 0 1.685 0h19.382c.927 0 1.686.893 1.686 1.984v18.849c0 1.09-.759 1.984-1.686 1.984h-3.792l2.781 5.654a1.101 1.101 0 01-.337 1.39c-.421.297-.927.098-1.18-.398l-3.202-6.646H7.67zM1.685 1.984v18.849h19.382V1.984H1.685zm28.23 2.877L30 18.253c0 .695-.674 1.19-1.18.893l-6.32-3.571v-2.183h.169l5.73 3.274-.084-10.317-5.73 3.075V7.242l6.151-3.274c.59-.297 1.18.199 1.18.893zm-17.275.1c.506 0 .843.396.843.991s-.337.992-.843.992H5.056c-.505 0-.843-.397-.843-.992s.338-.992.843-.992h7.584z" _fill="#FFF" fill-rule="nonzero"/>'
  }
})
