/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'navigation': {
    width: 30,
    height: 30,
    viewBox: '0 0 30 30',
    data: '<path pid="0" d="M28.101 16.14H18.038c-.95 0-1.899.759-1.899 1.898v10.063c0 .95.76 1.899 1.899 1.899h10.063c.95 0 1.899-.76 1.899-1.899V18.038c0-.95-.95-1.899-1.899-1.899zm.19 10.632c0 .76-.57 1.52-1.519 1.52h-7.405c-.76 0-1.519-.57-1.519-1.52v-7.405c0-.76.57-1.519 1.52-1.519h7.404c.76 0 1.52.57 1.52 1.52v7.404zM11.962 16.14H1.899C.949 16.14 0 16.9 0 18.038v10.063C0 29.051.76 30 1.899 30h10.063c.95 0 1.899-.76 1.899-1.899V18.038c0-.95-.76-1.899-1.899-1.899zm.19 10.633c0 .76-.57 1.52-1.52 1.52H3.229c-.76 0-1.52-.57-1.52-1.52v-7.405c0-.76.57-1.519 1.52-1.519h7.405c.76 0 1.519.57 1.519 1.52v7.404zM1.899 13.861h10.063c.95 0 1.899-.76 1.899-1.899V1.899C13.86.949 13.1 0 11.962 0H1.899C.949 0 0 .76 0 1.899v10.063c0 .95.95 1.899 1.899 1.899zm-.19-10.633c0-.76.76-1.52 1.519-1.52h7.405c.76 0 1.519.57 1.519 1.52v7.405c0 .76-.57 1.519-1.52 1.519H3.229c-.76 0-1.52-.76-1.52-1.52V3.229zM30 .949c0 .57-.38.95-.95.95H17.09c-.57 0-.95-.38-.95-.95S16.52 0 17.09 0H29.05c.38.19.949.57.949.95zm0 5.317c0 .57-.38.95-.95.95H17.09c-.57 0-.95-.38-.95-.95s.38-.95.95-.95H29.05c.38 0 .949.38.949.95zm0 5.126c0 .57-.38.95-.95.95H17.09c-.57 0-.95-.38-.95-.95s.38-.949.95-.949H29.05c.38.19.949.57.949.95z" _fill="#FFF" fill-rule="nonzero"/>'
  }
})
